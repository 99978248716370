"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

!function () {
    "use strict";

    function a(a) {
        return a.split("").reverse().join("");
    }

    function b(a, b) {
        return a.substring(0, b.length) === b;
    }

    function c(a, b) {
        return a.slice(-1 * b.length) === b;
    }

    function d(a, b, c) {
        if ((a[b] || a[c]) && a[b] === a[c]) throw new Error(b);
    }

    function e(a) {
        return "number" == typeof a && isFinite(a);
    }

    function f(a, b) {
        var c = Math.pow(10, b);
        return (Math.round(a * c) / c).toFixed(b);
    }

    function g(b, c, d, g, h, i, j, k, l, m, n, o) {
        var p,
            q,
            r,
            s = o,
            t = "",
            u = "";
        return i && (o = i(o)), e(o) ? (b && 0 === parseFloat(o.toFixed(b)) && (o = 0), 0 > o && (p = !0, o = Math.abs(o)), b !== !1 && (o = f(o, b)), o = o.toString(), -1 !== o.indexOf(".") ? (q = o.split("."), r = q[0], d && (t = d + q[1])) : r = o, c && (r = a(r).match(/.{1,3}/g), r = a(r.join(a(c)))), p && k && (u += k), g && (u += g), p && l && (u += l), u += r, u += t, h && (u += h), m && (u = m(u, s)), u) : !1;
    }

    function h(a, d, f, g, h, i, j, k, l, m, n, o) {
        var p,
            q = "";
        return n && (o = n(o)), o && "string" == typeof o ? (k && b(o, k) && (o = o.replace(k, ""), p = !0), g && b(o, g) && (o = o.replace(g, "")), l && b(o, l) && (o = o.replace(l, ""), p = !0), h && c(o, h) && (o = o.slice(0, -1 * h.length)), d && (o = o.split(d).join("")), f && (o = o.replace(f, ".")), p && (q += "-"), q += o, q = q.replace(/[^0-9\.\-.]/g, ""), "" === q ? !1 : (q = Number(q), j && (q = j(q)), e(q) ? q : !1)) : !1;
    }

    function i(a) {
        var b,
            c,
            e,
            f = {};
        for (b = 0; b < l.length; b += 1) {
            if (c = l[b], e = a[c], void 0 === e) f[c] = "negative" !== c || f.negativeBefore ? "mark" === c && "." !== f.thousand ? "." : !1 : "-";else if ("decimals" === c) {
                if (!(e >= 0 && 8 > e)) throw new Error(c);
                f[c] = e;
            } else if ("encoder" === c || "decoder" === c || "edit" === c || "undo" === c) {
                if ("function" != typeof e) throw new Error(c);
                f[c] = e;
            } else {
                if ("string" != typeof e) throw new Error(c);
                f[c] = e;
            }
        }return d(f, "mark", "thousand"), d(f, "prefix", "negative"), d(f, "prefix", "negativeBefore"), f;
    }

    function j(a, b, c) {
        var d,
            e = [];
        for (d = 0; d < l.length; d += 1) {
            e.push(a[l[d]]);
        }return e.push(c), b.apply("", e);
    }

    function k(a) {
        return this instanceof k ? void ("object" == (typeof a === "undefined" ? "undefined" : _typeof(a)) && (a = i(a), this.to = function (b) {
            return j(a, g, b);
        }, this.from = function (b) {
            return j(a, h, b);
        })) : new k(a);
    }
    var l = ["decimals", "thousand", "mark", "prefix", "postfix", "encoder", "decoder", "negativeBefore", "negative", "edit", "undo"];
    window.wNumb = k;
}(), function (a) {
    "use strict";

    function b(b) {
        return b instanceof a || a.zepto && a.zepto.isZ(b);
    }

    function c(b, c) {
        return "string" == typeof b && 0 === b.indexOf("-inline-") ? (this.method = c || "html", this.target = this.el = a(b.replace("-inline-", "") || "<div/>"), !0) : void 0;
    }

    function d(b) {
        if ("string" == typeof b && 0 !== b.indexOf("-")) {
            this.method = "val";
            var c = document.createElement("input");
            return c.name = b, c.type = "hidden", this.target = this.el = a(c), !0;
        }
    }

    function e(a) {
        return "function" == typeof a ? (this.target = !1, this.method = a, !0) : void 0;
    }

    function f(a, c) {
        return b(a) && !c ? (a.is("input, select, textarea") ? (this.method = "val", this.target = a.on("change.liblink", this.changeHandler)) : (this.target = a, this.method = "html"), !0) : void 0;
    }

    function g(a, c) {
        return b(a) && ("function" == typeof c || "string" == typeof c && a[c]) ? (this.method = c, this.target = a, !0) : void 0;
    }

    function h(b, c, d) {
        var e = this,
            f = !1;
        if (this.changeHandler = function (b) {
            var c = e.formatInstance.from(a(this).val());
            return c === !1 || isNaN(c) ? (a(this).val(e.lastSetValue), !1) : void e.changeHandlerMethod.call("", b, c);
        }, this.el = !1, this.formatInstance = d, a.each(k, function (a, d) {
            return f = d.call(e, b, c), !f;
        }), !f) throw new RangeError("(Link) Invalid Link.");
    }

    function i(a) {
        this.items = [], this.elements = [], this.origin = a;
    }

    function j(b, c, d, e) {
        0 === b && (b = this.LinkDefaultFlag), this.linkAPI || (this.linkAPI = {}), this.linkAPI[b] || (this.linkAPI[b] = new i(this));
        var f = new h(c, d, e || this.LinkDefaultFormatter);
        f.target || (f.target = a(this)), f.changeHandlerMethod = this.LinkConfirm(b, f.el), this.linkAPI[b].push(f, f.el), this.LinkUpdate(b);
    }
    var k = [c, d, e, f, g];
    h.prototype.set = function (a) {
        var b = Array.prototype.slice.call(arguments),
            c = b.slice(1);
        this.lastSetValue = this.formatInstance.to(a), c.unshift(this.lastSetValue), ("function" == typeof this.method ? this.method : this.target[this.method]).apply(this.target, c);
    }, i.prototype.push = function (a, b) {
        this.items.push(a), b && this.elements.push(b);
    }, i.prototype.reconfirm = function (a) {
        var b;
        for (b = 0; b < this.elements.length; b += 1) {
            this.origin.LinkConfirm(a, this.elements[b]);
        }
    }, i.prototype.remove = function () {
        var a;
        for (a = 0; a < this.items.length; a += 1) {
            this.items[a].target.off(".liblink");
        }for (a = 0; a < this.elements.length; a += 1) {
            this.elements[a].remove();
        }
    }, i.prototype.change = function (a) {
        if (this.origin.LinkIsEmitting) return !1;
        this.origin.LinkIsEmitting = !0;
        var b,
            c = Array.prototype.slice.call(arguments, 1);
        for (c.unshift(a), b = 0; b < this.items.length; b += 1) {
            this.items[b].set.apply(this.items[b], c);
        }this.origin.LinkIsEmitting = !1;
    }, a.fn.Link = function (b) {
        var c = this;
        if (b === !1) return c.each(function () {
            this.linkAPI && (a.map(this.linkAPI, function (a) {
                a.remove();
            }), delete this.linkAPI);
        });
        if (void 0 === b) b = 0;else if ("string" != typeof b) throw new Error("Flag must be string.");
        return {
            to: function to(a, d, e) {
                return c.each(function () {
                    j.call(this, b, a, d, e);
                });
            }
        };
    };
}(window.jQuery || window.Zepto), function (a) {
    "use strict";

    function b(b) {
        return a.grep(b, function (c, d) {
            return d === a.inArray(c, b);
        });
    }

    function c(a, b) {
        return Math.round(a / b) * b;
    }

    function d(a) {
        return "number" == typeof a && !isNaN(a) && isFinite(a);
    }

    function e(a) {
        var b = Math.pow(10, 7);
        return Number((Math.round(a * b) / b).toFixed(7));
    }

    function f(a, b, c) {
        a.addClass(b), setTimeout(function () {
            a.removeClass(b);
        }, c);
    }

    function g(a) {
        return Math.max(Math.min(a, 100), 0);
    }

    function h(b) {
        return a.isArray(b) ? b : [b];
    }

    function i(a, b) {
        return 100 / (b - a);
    }

    function j(a, b) {
        return 100 * b / (a[1] - a[0]);
    }

    function k(a, b) {
        return j(a, a[0] < 0 ? b + Math.abs(a[0]) : b - a[0]);
    }

    function l(a, b) {
        return b * (a[1] - a[0]) / 100 + a[0];
    }

    function m(a, b) {
        for (var c = 1; a >= b[c];) {
            c += 1;
        }return c;
    }

    function n(a, b, c) {
        if (c >= a.slice(-1)[0]) return 100;
        var d,
            e,
            f,
            g,
            h = m(c, a);
        return d = a[h - 1], e = a[h], f = b[h - 1], g = b[h], f + k([d, e], c) / i(f, g);
    }

    function o(a, b, c) {
        if (c >= 100) return a.slice(-1)[0];
        var d,
            e,
            f,
            g,
            h = m(c, b);
        return d = a[h - 1], e = a[h], f = b[h - 1], g = b[h], l([d, e], (c - f) * i(f, g));
    }

    function p(a, b, d, e) {
        if (100 === e) return e;
        var f,
            g,
            h = m(e, a);
        return d ? (f = a[h - 1], g = a[h], e - f > (g - f) / 2 ? g : f) : b[h - 1] ? a[h - 1] + c(e - a[h - 1], b[h - 1]) : e;
    }

    function q(a, b, c) {
        var e;
        if ("number" == typeof b && (b = [b]), "[object Array]" !== Object.prototype.toString.call(b)) throw new Error("noUiSlider: 'range' contains invalid value.");
        if (e = "min" === a ? 0 : "max" === a ? 100 : parseFloat(a), !d(e) || !d(b[0])) throw new Error("noUiSlider: 'range' value isn't numeric.");
        c.xPct.push(e), c.xVal.push(b[0]), e ? c.xSteps.push(isNaN(b[1]) ? !1 : b[1]) : isNaN(b[1]) || (c.xSteps[0] = b[1]);
    }

    function r(a, b, c) {
        return b ? void (c.xSteps[a] = j([c.xVal[a], c.xVal[a + 1]], b) / i(c.xPct[a], c.xPct[a + 1])) : !0;
    }

    function s(a, b, c, d) {
        this.xPct = [], this.xVal = [], this.xSteps = [d || !1], this.xNumSteps = [!1], this.snap = b, this.direction = c;
        var e,
            f = this;
        for (e in a) {
            a.hasOwnProperty(e) && q(e, a[e], f);
        }f.xNumSteps = f.xSteps.slice(0);
        for (e in f.xNumSteps) {
            f.xNumSteps.hasOwnProperty(e) && r(Number(e), f.xNumSteps[e], f);
        }
    }

    function t(a, b) {
        if (!d(b)) throw new Error("noUiSlider: 'step' is not numeric.");
        a.singleStep = b;
    }

    function u(b, c) {
        if ("object" != (typeof c === "undefined" ? "undefined" : _typeof(c)) || a.isArray(c)) throw new Error("noUiSlider: 'range' is not an object.");
        if (void 0 === c.min || void 0 === c.max) throw new Error("noUiSlider: Missing 'min' or 'max' in 'range'.");
        b.spectrum = new s(c, b.snap, b.dir, b.singleStep);
    }

    function v(b, c) {
        if (c = h(c), !a.isArray(c) || !c.length || c.length > 2) throw new Error("noUiSlider: 'start' option is incorrect.");
        b.handles = c.length, b.start = c;
    }

    function w(a, b) {
        if (a.snap = b, "boolean" != typeof b) throw new Error("noUiSlider: 'snap' option must be a boolean.");
    }

    function x(a, b) {
        if (a.animate = b, "boolean" != typeof b) throw new Error("noUiSlider: 'animate' option must be a boolean.");
    }

    function y(a, b) {
        if ("lower" === b && 1 === a.handles) a.connect = 1;else if ("upper" === b && 1 === a.handles) a.connect = 2;else if (b === !0 && 2 === a.handles) a.connect = 3;else {
            if (b !== !1) throw new Error("noUiSlider: 'connect' option doesn't match handle count.");
            a.connect = 0;
        }
    }

    function z(a, b) {
        switch (b) {
            case "horizontal":
                a.ort = 0;
                break;
            case "vertical":
                a.ort = 1;
                break;
            default:
                throw new Error("noUiSlider: 'orientation' option is invalid.");
        }
    }

    function A(a, b) {
        if (!d(b)) throw new Error("noUiSlider: 'margin' option must be numeric.");
        if (a.margin = a.spectrum.getMargin(b), !a.margin) throw new Error("noUiSlider: 'margin' option is only supported on linear sliders.");
    }

    function B(a, b) {
        if (!d(b)) throw new Error("noUiSlider: 'limit' option must be numeric.");
        if (a.limit = a.spectrum.getMargin(b), !a.limit) throw new Error("noUiSlider: 'limit' option is only supported on linear sliders.");
    }

    function C(a, b) {
        switch (b) {
            case "ltr":
                a.dir = 0;
                break;
            case "rtl":
                a.dir = 1, a.connect = [0, 2, 1, 3][a.connect];
                break;
            default:
                throw new Error("noUiSlider: 'direction' option was not recognized.");
        }
    }

    function D(a, b) {
        if ("string" != typeof b) throw new Error("noUiSlider: 'behaviour' must be a string containing options.");
        var c = b.indexOf("tap") >= 0,
            d = b.indexOf("drag") >= 0,
            e = b.indexOf("fixed") >= 0,
            f = b.indexOf("snap") >= 0;
        a.events = {
            tap: c || f,
            drag: d,
            fixed: e,
            snap: f
        };
    }

    function E(a, b) {
        if (a.format = b, "function" == typeof b.to && "function" == typeof b.from) return !0;
        throw new Error("noUiSlider: 'format' requires 'to' and 'from' methods.");
    }

    function F(b) {
        var c,
            d = {
            margin: 0,
            limit: 0,
            animate: !0,
            format: Y
        };
        return c = {
            step: {
                r: !1,
                t: t
            },
            start: {
                r: !0,
                t: v
            },
            connect: {
                r: !0,
                t: y
            },
            direction: {
                r: !0,
                t: C
            },
            snap: {
                r: !1,
                t: w
            },
            animate: {
                r: !1,
                t: x
            },
            range: {
                r: !0,
                t: u
            },
            orientation: {
                r: !1,
                t: z
            },
            margin: {
                r: !1,
                t: A
            },
            limit: {
                r: !1,
                t: B
            },
            behaviour: {
                r: !0,
                t: D
            },
            format: {
                r: !1,
                t: E
            }
        }, b = a.extend({
            connect: !1,
            direction: "ltr",
            behaviour: "tap",
            orientation: "horizontal"
        }, b), a.each(c, function (a, c) {
            if (void 0 === b[a]) {
                if (c.r) throw new Error("noUiSlider: '" + a + "' is required.");
                return !0;
            }
            c.t(d, b[a]);
        }), d.style = d.ort ? "top" : "left", d;
    }

    function G(a, b, c) {
        var d = a + b[0],
            e = a + b[1];
        return c ? (0 > d && (e += Math.abs(d)), e > 100 && (d -= e - 100), [g(d), g(e)]) : [d, e];
    }

    function H(a) {
        a.preventDefault();
        var b,
            c,
            d = 0 === a.type.indexOf("touch"),
            e = 0 === a.type.indexOf("mouse"),
            f = 0 === a.type.indexOf("pointer"),
            g = a;
        return 0 === a.type.indexOf("MSPointer") && (f = !0), a.originalEvent && (a = a.originalEvent), d && (b = a.changedTouches[0].pageX, c = a.changedTouches[0].pageY), (e || f) && (f || void 0 !== window.pageXOffset || (window.pageXOffset = document.documentElement.scrollLeft, window.pageYOffset = document.documentElement.scrollTop), b = a.clientX + window.pageXOffset, c = a.clientY + window.pageYOffset), g.points = [b, c], g.cursor = e, g;
    }

    function I(b, c) {
        var d = a("<div><div/></div>").addClass(X[2]),
            e = ["-lower", "-upper"];
        return b && e.reverse(), d.children().addClass(X[3] + " " + X[3] + e[c]), d;
    }

    function J(a, b, c) {
        switch (a) {
            case 1:
                b.addClass(X[7]), c[0].addClass(X[6]);
                break;
            case 3:
                c[1].addClass(X[6]);
            case 2:
                c[0].addClass(X[7]);
            case 0:
                b.addClass(X[6]);
        }
    }

    function K(a, b, c) {
        var d,
            e = [];
        for (d = 0; a > d; d += 1) {
            e.push(I(b, d).appendTo(c));
        }return e;
    }

    function L(b, c, d) {
        return d.addClass([X[0], X[8 + b], X[4 + c]].join(" ")), a("<div/>").appendTo(d).addClass(X[1]);
    }

    function M(b, c, d) {
        function e() {
            return B[["width", "height"][c.ort]]();
        }

        function i(a) {
            var b,
                c = [D.val()];
            for (b = 0; b < a.length; b += 1) {
                D.trigger(a[b], c);
            }
        }

        function j(a) {
            return 1 === a.length ? a[0] : c.dir ? a.reverse() : a;
        }

        function k(a) {
            return function (b, c) {
                D.val([a ? null : c, a ? c : null], !0);
            };
        }

        function l(b) {
            var c = a.inArray(b, M);
            D[0].linkAPI && D[0].linkAPI[b] && D[0].linkAPI[b].change(I[c], C[c].children(), D);
        }

        function m(b, d) {
            var e = a.inArray(b, M);
            return d && d.appendTo(C[e].children()), c.dir && c.handles > 1 && (e = 1 === e ? 0 : 1), k(e);
        }

        function n() {
            var a, b;
            for (a = 0; a < M.length; a += 1) {
                this.linkAPI && this.linkAPI[b = M[a]] && this.linkAPI[b].reconfirm(b);
            }
        }

        function o(a, b, d, e) {
            return a = a.replace(/\s/g, V + " ") + V, b.on(a, function (a) {
                return D.attr("disabled") ? !1 : D.hasClass(X[14]) ? !1 : (a = H(a), a.calcPoint = a.points[c.ort], void d(a, e));
            });
        }

        function p(a, b) {
            var c,
                d = b.handles || C,
                f = !1,
                g = 100 * (a.calcPoint - b.start) / e(),
                h = d[0][0] !== C[0][0] ? 1 : 0;
            c = G(g, b.positions, d.length > 1), f = u(d[0], c[h], 1 === d.length), d.length > 1 && (f = u(d[1], c[h ? 0 : 1], !1) || f), f && i(["slide"]);
        }

        function q(b) {
            a("." + X[15]).removeClass(X[15]), b.cursor && a("body").css("cursor", "").off(V), T.off(V), D.removeClass(X[12]), i(["set", "change"]);
        }

        function r(b, c) {
            1 === c.handles.length && c.handles[0].children().addClass(X[15]), b.stopPropagation(), o(W.move, T, p, {
                start: b.calcPoint,
                handles: c.handles,
                positions: [E[0], E[C.length - 1]]
            }), o(W.end, T, q, null), b.cursor && (a("body").css("cursor", a(b.target).css("cursor")), C.length > 1 && D.addClass(X[12]), a("body").on("selectstart" + V, !1));
        }

        function s(b) {
            var d,
                g = b.calcPoint,
                h = 0;
            b.stopPropagation(), a.each(C, function () {
                h += this.offset()[c.style];
            }), h = h / 2 > g || 1 === C.length ? 0 : 1, g -= B.offset()[c.style], d = 100 * g / e(), c.events.snap || f(D, X[14], 300), u(C[h], d), i(["slide", "set", "change"]), c.events.snap && r(b, {
                handles: [C[h]]
            });
        }

        function t(a) {
            var b, c;
            if (!a.fixed) for (b = 0; b < C.length; b += 1) {
                o(W.start, C[b].children(), r, {
                    handles: [C[b]]
                });
            }a.tap && o(W.start, B, s, {
                handles: C
            }), a.drag && (c = B.find("." + X[7]).addClass(X[10]), a.fixed && (c = c.add(B.children().not(c).children())), o(W.start, c, r, {
                handles: C
            }));
        }

        function u(a, b, d) {
            var e = a[0] !== C[0][0] ? 1 : 0,
                f = E[0] + c.margin,
                h = E[1] - c.margin,
                i = E[0] + c.limit,
                j = E[1] - c.limit;
            return C.length > 1 && (b = e ? Math.max(b, f) : Math.min(b, h)), d !== !1 && c.limit && C.length > 1 && (b = e ? Math.min(b, i) : Math.max(b, j)), b = F.getStep(b), b = g(parseFloat(b.toFixed(7))), b === E[e] ? !1 : (a.css(c.style, b + "%"), a.is(":first-child") && a.toggleClass(X[17], b > 50), E[e] = b, I[e] = F.fromStepping(b), l(M[e]), !0);
        }

        function v(a, b) {
            var d, e, f;
            for (c.limit && (a += 1), d = 0; a > d; d += 1) {
                e = d % 2, f = b[e], null !== f && f !== !1 && ("number" == typeof f && (f = String(f)), f = c.format.from(f), (f === !1 || isNaN(f) || u(C[e], F.toStepping(f), d === 3 - c.dir) === !1) && l(M[e]));
            }
        }

        function w(a) {
            if (D[0].LinkIsEmitting) return this;
            var b,
                d = h(a);
            return c.dir && c.handles > 1 && d.reverse(), c.animate && -1 !== E[0] && f(D, X[14], 300), b = C.length > 1 ? 3 : 1, 1 === d.length && (b = 1), v(b, d), i(["set"]), this;
        }

        function x() {
            var a,
                b = [];
            for (a = 0; a < c.handles; a += 1) {
                b[a] = c.format.to(I[a]);
            }return j(b);
        }

        function y() {
            return a(this).off(V).removeClass(X.join(" ")).empty(), delete this.LinkUpdate, delete this.LinkConfirm, delete this.LinkDefaultFormatter, delete this.LinkDefaultFlag, delete this.reappend, delete this.vGet, delete this.vSet, delete this.getCurrentStep, delete this.getInfo, delete this.destroy, d;
        }

        function z() {
            var b = a.map(E, function (a, b) {
                var c = F.getApplicableStep(a),
                    d = I[b],
                    e = c[2],
                    f = d - c[2] >= c[1] ? c[2] : c[0];
                return [[f, e]];
            });
            return j(b);
        }

        function A() {
            return d;
        }
        var B,
            C,
            D = a(b),
            E = [-1, -1],
            F = c.spectrum,
            I = [],
            M = ["lower", "upper"].slice(0, c.handles);
        if (c.dir && M.reverse(), b.LinkUpdate = l, b.LinkConfirm = m, b.LinkDefaultFormatter = c.format, b.LinkDefaultFlag = "lower", b.reappend = n, D.hasClass(X[0])) throw new Error("Slider was already initialized.");
        B = L(c.dir, c.ort, D), C = K(c.handles, c.dir, B), J(c.connect, D, C), t(c.events), b.vSet = w, b.vGet = x, b.destroy = y, b.getCurrentStep = z, b.getOriginalOptions = A, b.getInfo = function () {
            return [F, c.style, c.ort];
        }, D.val(c.start);
    }

    function N(a) {
        if (!this.length) throw new Error("noUiSlider: Can't initialize slider on empty selection.");
        var b = F(a, this);
        return this.each(function () {
            M(this, b, a);
        });
    }

    function O(b) {
        return this.each(function () {
            if (!this.destroy) return void a(this).noUiSlider(b);
            var c = a(this).val(),
                d = this.destroy(),
                e = a.extend({}, d, b);
            a(this).noUiSlider(e), this.reappend(), d.start === e.start && a(this).val(c);
        });
    }

    function P() {
        return this[0][arguments.length ? "vSet" : "vGet"].apply(this[0], arguments);
    }

    function Q(b, c, d, e) {
        if ("range" === c || "steps" === c) return b.xVal;
        if ("count" === c) {
            var f,
                g = 100 / (d - 1),
                h = 0;
            for (d = []; (f = h++ * g) <= 100;) {
                d.push(f);
            }c = "positions";
        }
        return "positions" === c ? a.map(d, function (a) {
            return b.fromStepping(e ? b.getStep(a) : a);
        }) : "values" === c ? e ? a.map(d, function (a) {
            return b.fromStepping(b.getStep(b.toStepping(a)));
        }) : d : void 0;
    }

    function R(c, d, e, f) {
        var g = c.direction,
            h = {},
            i = c.xVal[0],
            j = c.xVal[c.xVal.length - 1],
            k = !1,
            l = !1,
            m = 0;
        return c.direction = 0, f = b(f.slice().sort(function (a, b) {
            return a - b;
        })), f[0] !== i && (f.unshift(i), k = !0), f[f.length - 1] !== j && (f.push(j), l = !0), a.each(f, function (b) {
            var g,
                i,
                j,
                n,
                o,
                p,
                q,
                r,
                s,
                t,
                u = f[b],
                v = f[b + 1];
            if ("steps" === e && (g = c.xNumSteps[b]), g || (g = v - u), u !== !1 && void 0 !== v) for (i = u; v >= i; i += g) {
                for (n = c.toStepping(i), o = n - m, r = o / d, s = Math.round(r), t = o / s, j = 1; s >= j; j += 1) {
                    p = m + j * t, h[p.toFixed(5)] = ["x", 0];
                }q = a.inArray(i, f) > -1 ? 1 : "steps" === e ? 2 : 0, b || !k || u || (q = 0), i === v && l || (h[n.toFixed(5)] = [i, q]), m = n;
            }
        }), c.direction = g, h;
    }

    function S(b, c, d, e, f, g) {
        function h(a, b) {
            return ["-normal", "-large", "-sub"][a && f ? f(b, a) : a];
        }

        function i(a, c, d) {
            return 'class="' + c + " " + c + "-" + k + " " + c + h(d[1], d[0]) + '" style="' + b + ": " + a + '%"';
        }

        function j(a, b) {
            d && (a = 100 - a), l.append("<div " + i(a, "noUi-marker", b) + "></div>"), b[1] && l.append("<div " + i(a, "noUi-value", b) + ">" + g.to(b[0]) + "</div>");
        }
        var k = ["horizontal", "vertical"][c],
            l = a("<div/>");
        return l.addClass("noUi-pips noUi-pips-" + k), a.each(e, j), l;
    }
    var T = a(document),
        U = a.fn.val,
        V = ".nui",
        W = window.navigator.pointerEnabled ? {
        start: "pointerdown",
        move: "pointermove",
        end: "pointerup"
    } : window.navigator.msPointerEnabled ? {
        start: "MSPointerDown",
        move: "MSPointerMove",
        end: "MSPointerUp"
    } : {
        start: "mousedown touchstart",
        move: "mousemove touchmove",
        end: "mouseup touchend"
    },
        X = ["noUi-target", "noUi-base", "noUi-origin", "noUi-handle", "noUi-horizontal", "noUi-vertical", "noUi-background", "noUi-connect", "noUi-ltr", "noUi-rtl", "noUi-dragable", "", "noUi-state-drag", "", "noUi-state-tap", "noUi-active", "", "noUi-stacking"];
    s.prototype.getMargin = function (a) {
        return 2 === this.xPct.length ? j(this.xVal, a) : !1;
    }, s.prototype.toStepping = function (a) {
        return a = n(this.xVal, this.xPct, a), this.direction && (a = 100 - a), a;
    }, s.prototype.fromStepping = function (a) {
        return this.direction && (a = 100 - a), e(o(this.xVal, this.xPct, a));
    }, s.prototype.getStep = function (a) {
        return this.direction && (a = 100 - a), a = p(this.xPct, this.xSteps, this.snap, a), this.direction && (a = 100 - a), a;
    }, s.prototype.getApplicableStep = function (a) {
        var b = m(a, this.xPct),
            c = 100 === a ? 2 : 1;
        return [this.xNumSteps[b - 2], this.xVal[b - c], this.xNumSteps[b - c]];
    }, s.prototype.convert = function (a) {
        return this.getStep(this.toStepping(a));
    };
    var Y = {
        to: function to(a) {
            return a.toFixed(2);
        },
        from: Number
    };
    a.fn.val = function () {
        function b(a) {
            return a.hasClass(X[0]) ? P : U;
        }
        var c = arguments,
            d = a(this[0]);
        return arguments.length ? this.each(function () {
            b(a(this)).apply(a(this), c);
        }) : b(d).call(d);
    }, a.fn.noUiSlider = function (a, b) {
        switch (a) {
            case "step":
                return this[0].getCurrentStep();
            case "options":
                return this[0].getOriginalOptions();
        }
        return (b ? O : N).call(this, a);
    }, a.fn.noUiSlider_pips = function (b) {
        var c = b.mode,
            d = b.density || 1,
            e = b.filter || !1,
            f = b.values || !1,
            g = b.format || {
            to: Math.round
        },
            h = b.stepped || !1;
        return this.each(function () {
            var b = this.getInfo(),
                i = Q(b[0], c, f, h),
                j = R(b[0], d, c, i);
            return a(this).append(S(b[1], b[2], b[0].direction, j, e, g));
        });
    };
}(window.jQuery || window.Zepto);

var app = {
    setCookie: function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        if (exdays != false) {
            var expires = "expires=" + d.toUTCString();
            document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
        } else {
            document.cookie = cname + "=" + cvalue + "; path=/";
        }
    },

    getCookie: function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    },

    viewMoreBlog: function viewMoreBlog() {
        var items = $('.blog-listings-item');
        var viewMore = $('#load-more-btn');
        var clicked = 1;
        var page = app.getCookie('blog');
        if (page > 1) {
            clicked = page;
        }

        var x = 1;
        var target = 3 * clicked;
        $.each(items, function (key, row) {
            var row = $(row);
            if (x > target) {
                row.addClass('hide');
            }
            x++;
        });

        $('#load-more-btn').click(function () {
            clicked++;
            x = 0;
            app.setCookie('blog', clicked);
            $.each(items, function (key, row) {
                if (x < 3) {
                    var row = $(row);
                    if (row.hasClass('hide') == true) {
                        row.slideDown().removeClass('hide');
                        x++;
                    }
                }
            });
            var hidden = $('#blogWrap .hide');
            if (hidden.length == 0) viewMore.hide();
        });
    },

    dragDrop: function dragDrop() {
        var active = "";
        var label = $('#dropzone #label');

        var links = document.querySelectorAll('.thumb');
        el = null;

        for (var i = 0; i < links.length; i++) {
            el = links[i];
            el.setAttribute('draggable', 'true');
            addEvent(el, 'dragstart', function (e) {
                e.dataTransfer.effectAllowed = 'copy'; // only dropEffect='copy' will be dropable
                e.dataTransfer.setData('Text', this.id); // required otherwise doesn't work
                active = this.id;
            });
        }

        // Drag and drop
        var dropzone = $('#dropzone');
        addEvent(dropzone, 'dragover', function (e) {
            if (e.preventDefault) e.preventDefault(); // allows us to drop
            label.html("Now drop your image");
            dropzone.addClass('triggered');
            return false;
        });

        // to get IE to work
        addEvent(dropzone, 'dragenter', function (e) {
            //$('#dropzone div span').html("Now drop your image");
            return false;
        });

        addEvent(dropzone, 'dragleave', function () {
            label.html("<strong>Drag</strong> one of the sample files here to upload it");
            dropzone.removeClass('triggered');
        });

        addEvent(dropzone, 'drop', function (e) {
            e.preventDefault();
            e.stopPropagation(); // stops the browser from redirecting...why???
            label.hide();
            $('#uploading').show();
            app.uploadAnimation(1, active);
            return false;
        });
    },

    uploadAnimation: function uploadAnimation(x, active) {
        var x = parseInt(x) + 3;
        $('#uploading').html(x + "%");
        if (x < 101) {
            setTimeout("app.uploadAnimation(" + x + ", '" + active + "')", 50);
        } else {
            $('#uploading').hide();
            $('#dropzone #label').html("<strong>Image Uploaded</strong>").fadeIn();
            $('#' + active).css('opacity', 0);
            $('#dropzone').removeClass('triggered');
        }
    },

    mobileMenu: function mobileMenu() {
        var menu_btn = $('#mobile-burger-btn');
        var page_body = $('#page-wrap-inner');
        var body = $('body');

        menu_btn.click(function () {
            if (menu_btn.hasClass('is-active') == true) {
                menu_btn.removeClass('is-active');
                page_body.removeClass('opened');
                //body.removeClass('fixed');
            } else {
                menu_btn.addClass('is-active');
                page_body.addClass('opened');
                //body.addClass('fixed');
            }
        });
    },

    validateEmail: function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },

    downloadApp: function downloadApp() {
        var dropdown = $('#dropdown_sel');
        var dropdown_icons = $('#dropdown_icons_inner i');
        dropdown.click(function () {
            $(dropdown_icons[1]).insertBefore(dropdown_icons[0]);
            var source = $(dropdown_icons[1]).data('source');
            $('#download-btn-txt').attr("href", source);
            dropdown_icons = $('#dropdown_icons_inner i');
        });

        // detect if android;
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        if (isAndroid == true) {
            dropdown.trigger("click");
        }
    },

    validateForm: function validateForm() {
        var errors = 0;
        var form = $('#project_enquiry').length == 1 ? 'project' : 'contact';
        var target = form == 'project' ? $('.project-form') : $('#enquiry');

        $('html, body').animate({
            scrollTop: target.offset().top - 20
        }, 'slow');

        var name_txt = $('#name_txt');
        if (name_txt.val().length < 3) {
            name_txt.addClass('required_border');
            errors++;
        } else {
            name_txt.removeClass('required_border');
        }

        var email_txt = $('#email_txt');
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(email_txt.val())) {
            email_txt.addClass('required_border');
            errors++;
        } else {
            email_txt.removeClass('required_border');
        }

        if (form == 'project') {
            // VALIDATE START A PROJET
        } else {
            // VALIDATE ENQUIRY FORM
            var message_txt = $('#message_txt');
            if (message_txt.val().length < 3) {
                message_txt.addClass('required_border');
                errors++;
            } else {
                message_txt.removeClass('required_border');
            }
        }

        return errors == 0 ? true : false;
    },

    eventTracking: function eventTracking(anchors) {
        $.each(anchors, function (key, row) {
            var row = $(row);
            row.click(function () {
                var value = row.html();
                var type = value.search("@") == -1 ? 'Telephone' : 'Email';
                ga('send', 'event', 'Link', type, value);
            });
        });
    },

    init: function init() {
        app.mobileMenu();
        app.viewMoreBlog();

        if ($('#enquiry,#project_enquiry').length > 0) {

            var form = $('#enquiry form, #project_enquiry form');
            form.submit(function (e) {
                e.preventDefault();
                var valid = app.validateForm();
                if (valid == true) {
                    form.slideUp(function () {
                        $('#loader').fadeIn();
                        $(this).unbind('submit').submit();
                    });
                }
            });
        }

        var anchors = $('a[href^="mailto:"],a[href^="tel:"]');
        if (anchors.length > 0) {
            app.eventTracking(anchors);
        }
        if ($('#dropdown_sel').length == 1) {
            app.downloadApp();
        }

        $(".checkbox-wrap-item").click(function () {
            var id = $(this).attr("id").replace("checkbox-option-", "");
            var selected = $("#checkbox-option-" + id);
            if (selected.length == 1) {
                var input = selected.find("input");
                var type = input.attr("type");
                if (type == "checkbox") {
                    if (selected.hasClass("selected") == true) {
                        selected.removeClass("selected");
                        input.prop("checked", false);
                    } else {
                        selected.addClass("selected");
                        input.prop("checked", "checked");
                    }
                } else if (type == "radio") {
                    var radio_sel = $(".rad_sel");
                    radio_sel.prop("checked", false);
                    $(".radio-items").removeClass("selected");
                    input.prop("checked", "checked");
                    $("#checkbox-option-" + id).addClass("selected");
                }
            }
        });

        if ($("#timeline_slider_sel").length == 1) {
            $("#timeline_slider_sel").noUiSlider({
                animate: true,
                start: [3],
                step: 1,
                range: {
                    min: [2],
                    max: [12]
                },
                format: wNumb({
                    decimals: 0,
                    thousand: "."
                })
            }).Link("lower").to($("#timeline_slider_value span")).Link("lower").to($("#timeline_txt"));

            $("#budget_slider_sel").noUiSlider({
                animate: true,
                start: [3000, 7500],
                range: {
                    min: [1000],
                    max: [20000]
                },
                step: 250,
                format: wNumb({
                    decimals: 0,
                    thousand: ",",
                    prefix: "£"
                })
            }).Link("lower").to($("#budget_slider_lower")).Link("lower").to($("#budget_range_start_txt")).Link("upper").to($("#budget_slider_higher")).Link("upper").to($("#budget_range_end_txt"));
        }
    }
};

$("a.subnav-mobile-button").click(function () {
    $(".subnav-mobile h1").toggle();
    $("a.subnav-mobile-button").toggleClass("open");
});

$(document).ready(function () {
    app.init();
});

$(document).scroll(function () {
    var scrollTop = $(document).scrollTop();
    var mobileBtn = $('#mobile-burger-btn');
    if (scrollTop > 40) {
        mobileBtn.addClass('scrolled');
    } else {
        mobileBtn.removeClass('scrolled');
    }
});